import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Eko from "/src/components/img/eko"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

export default () => (
  <Layout>
    <SEO title="Historie lesů na území České republiky" />
    <h1>Historie lesů na území České republiky</h1>
    <p><strong>Historie lesů</strong> by se velmi stručně dala definovat následovně. Pokud populace rostla či narážela na svůj strop, docházelo v&nbsp;minulosti k&nbsp;přetěžování a devastaci lesů. Naopak při katastrofách se lesy obnovovaly a rozrůstaly se (typicky třicetiletá válka).</p>
    <hr />
    <h2>Raný vývoj</h2>
    <p>Nejprve byl v&nbsp;našich zemích les považován za nepřátelské území a neomezený zdroj. Již kolem roku 1280 má však Praha velké problémy s nedostatkem dřevem, okolní lesy byly vykáceny a do města byly klády plaveny po Vltavě.</p>
    <p>Roku 1350 je vydán Majestas Carolinas, který mimo jiné zakazuje vypalování lesů. Dokument sice nevstoupil v&nbsp;platnost, ale de facto byl naplňován.</p>
    <p>Zhruba od roku 1450 si subjekty začínají v&nbsp;lesích pomalu zřizovat managment, církevní lesy měly obecně díky plánování na staletí nejlepší stav. V&nbsp;15. a 16. století je již devastace lesů obrovská, například Orlické hory byly postupně vytěženy až po hřeben.</p>
    <p>Dále víme, že v&nbsp;roce 1464 bylo za vlády Jiřího z&nbsp;Poděbrad povoleno v&nbsp;Jehnicích brát si dříví v&nbsp;lese pro vlastní potřebu, ale jen v&nbsp;těch místech, kde ukáže hajný. Obzvláště šlechta si zvolna začíná pořizovat hajné, kteří rozumí tomu, kde a co kácet. Příliš rozšířený fenomén to ale ještě není.</p>
    <p>Lesy jsou velmi přetěžovány, chybí v&nbsp;nich staré stromy kvůli velkému hladu po dříví. Byly káceny již 60 či dokonce 40leté stromy. Kupříkladu dnes jsou některé těženy až po 120 letech.</p>
    <p>Po roce 1500 se prosazuje síje jedle v&nbsp;Českých zemích, a to kvůli rovnějšímu dřevu. Na mnoha stanovištích tak jedle nejsou přirozeně, ačkoliv se to dlouho předpokládalo.</p>
    <p>Během 30leté války přichází <strong>velká regenerace lesů</strong>. Zhruba 1/3 vesnic zanikla a dochází k&nbsp;pustnutí venkova. Zhruba kolem roku 1680 však dochází k&nbsp;opětovnému „útoku na lesy“, v&nbsp;ekuménách měst již o něco dříve.</p>
    <p>V&nbsp;této době se již těžba velmi rozšiřuje i do hor, k&nbsp;odlesňování hor dochází především kvůli rozvíjejícímu se sklářství a jiným odvětvím průmyslu. Rovněž přibývá dosti lidí, ve městech se objevuje tzv. <em>zemědělská </em>chudina. V&nbsp;roce 1760 pak kvůli nedostatku dřeva (na palivo) krachuje na úpatí Jeseníků železárna.</p>
    <hr />
    <h2>Vláda Marie Terezie a Josefa II.</h2>
    <p>Roku 1754 je vydán <strong>Tereziánský lesní patent</strong>, který mimo jiné určoval, že kdo má les od určité výměry, musí si najmout odborného lesního správce.&nbsp; Do teď bylo běžnou praxí, že se paseky nechaly zalesnit náletem, sázení se příliš nepraktikovalo. Obecní lesy menších sídel však nechtěly dávat peníze na lesního správce, tyto lesy byly pak mimochodem také zpravidla nejvíce přetěžované a zpustlé.</p>
    <p>Rovněž byla za vlády Marie Terezie zakázána pastva v&nbsp;lese. &nbsp;Ta poškozovala pupeny stromů (obzvlášť kozy) a vypasením bylinného patra docházelo k&nbsp;ochuzování lesů o živiny a taktéž k&nbsp;okyselení, tím byl jejich růst zpomalen.</p>
    <p>Zákaz pastvy však nebyl prakticky respektován, toto se mění k&nbsp;lepšímu až za vlády Josefa II. alespoň ve šlechtických lesích, na&nbsp;lesních pozemcích sedláků dále pokračuje porušování zákazu a fakticky tak pastva v&nbsp;lesích končí až v&nbsp;polovině 19. století.</p>
    <p>V&nbsp;16. století došlo k&nbsp;vymizení vlků a tím vzrostla populace divočáků. To vedlo za vlády Josefa II. k&nbsp;takové devastaci zemědělské půdy, že na příkaz musela všechna panství divočáky vystřílet (mimo obor). Poddaní udělali šňůru a hnali divočáky před sebou, šlechta je pak v&nbsp;rámci zábavy vystřílela.</p>
    <p>Až v&nbsp;roce 1947 je malou změnou zákona povoleno pěstování divočáků v&nbsp;lesích, což vedlo ve 20. století k&nbsp;jejich opětovnému přemnožení a s&nbsp;tzv. přezvěřením se potýkáme dodnes.</p>
    <p>Ze zavedení <strong>Josefínského katastru</strong> do praxe v&nbsp;roce 1789 víme, že zalesnění bylo na našem území asi o 28 % menší než dnes. Zhruba 80 % lesů vlastnily soukromé osoby (především šlechta), 10 % obce, 8 % církve (dříve více, důsledek rušení klášterů) a 2 % stát a jiní.</p>
    <p>Zalesnění bylo v&nbsp;těchto dobách historicky nejnižší. Stále byl hlad po dřevě, a to i díky vzkvétání železářství a sklenářství, staví se kanály na plavení dříví (např. Schwarzenbergský). V&nbsp;roce 1780 začíná těžby uhlí na Ostravsku, která se postupně rozjede naplno. Těžba se vyplatila mimo jiné i proto, že dříví bylo tehdy velmi drahé. Podle lesáků byly naše lesy zachráněny právě díky počátku těžby uhlí.</p>
    <hr />
    <h2>Smrkové monokultury a plantáže</h2>
    <p>Na vhodná stanoviště byla nejprve uměle vysazována jedle, a to především pro její rovné dřevo. Tam, kde se nedala v&nbsp;nižších polohách pěstovat, docházelo k&nbsp;výsadbě borovice – její první výsadba se datuje do roku 1771 a šlo o porosty u Hodonína v&nbsp;panství Habsburků.</p>
    <p>Zhruba od roku 1800 dochází u nás k&nbsp;výsadbě smrkových plantáží podle <strong>saské lesnické školy. </strong>Smrky zde byly pěstovány v&nbsp;řádcích a pro hospodaření byl dokonce vydán předpis a správná metodika.</p>
    <p>Tento způsob hospodaření se stal velice populárním také díky tomu, že se smrk jevil ideálně. Rostl rychle, měl rovné dřevo a v&nbsp;1. generaci rostli bez problémů. Prve byly snahy o zalesňování smrkem i ve velmi nízkých polohách, ale zde příliš zasychal. Pro zajímavost, prořezávky mladých smrčků z&nbsp;těchto plantáží umožnily rozšíření tradice vánočních stromků.</p>
    <p>Nejprve se tyto plantáží sází v&nbsp;Čechách, v roce 1835 jsou již ale smrkové monokultury vysazovány i v&nbsp;Jeseníkách. První generace rostla skvěle, nicméně jak se později ukázalo, v&nbsp;druhé se začaly objevovat plísně a patogeny a dnešní třetí generace schne a je plošně napadena kůrovcem.</p>
    <p>V&nbsp;roce 1852 je vydán zákon 250, který platí se změnami neuvěřitelných 108 let až do roku 1960. Vychází v&nbsp;době důsledné státní byrokracie. Mimo jiné ustanovuje povinnost mít lesního hospodáře, dále umožňuje víceméně volný vstup do lesa (nicméně platí zákaz sběru hub, lesních plodů atd. bez povolení). Toto bylo na tehdejší dobu velmi pokrokové, například ve Spojeném království toto nebylo povoleno až do roku 2000 (Open Access Land Crow Act).</p>
    <hr />
    <h2>Zvedání horní hranice lesa</h2>
    <p>Ve 40. letech 19. století dochází k&nbsp;devastaci osad lavinami v&nbsp;Alpách, protože vlivem kácení lesů nemělo laviny co brzdit. To vyvolá snahu zvýšit nově stanovenou horní hranici lesa, po celém mocnářství tak dochází k&nbsp;sázení stromů k&nbsp;této hranici.</p>
    <p>I přesto však došlo v&nbsp;roce 1860 vykácení lesů až po horní hranici lesa na Pradědu, kde tedy naopak došlo k&nbsp;umělému snížení horní hranice lesa.</p>
    <p>V&nbsp;roce 1895 přicházejí do Krkonoš extrémní srážky, které přináší mury a obrovské povodně. Vlivem toho je zde kolem roku 1900 zakázána velkoplošná pastva a je povoleno pouze kosení trávy, proto se zde příliš neudržela kleč. Ta byla kácena kvůli potřebě luk pro hospodaření. Rovněž se začíná s&nbsp;mohutnými úpravami koryt, dochází k&nbsp;jejich opevňování a stavbě kaskád.</p>
    <p>Taktéž v&nbsp;Beskydech dochází vlivem povodní na přelomu století k&nbsp;zalesňování. Smrky se objevují i na pastvinách, kde ale vlivem přílišné živnosti půd vyhnívají.</p>
    <p>Rakousko-Uhersko v&nbsp;této době pořádá státní soutěž, komu se povede nejvíce zvednout horní hranici lesa. Soutěž vyhrávají Lichtenštejni s&nbsp;lesy na Šeráku v&nbsp;Jeseníku. Dodnes se tyto lesy nazývají cenové kultury (výhra ceny v&nbsp;soutěži). Pro zajímavost, lesy na Šeráku jsou nyní nejstarší rezervací na Moravě.</p>
    <hr />
    <h2>Vývoj v&nbsp;Československu</h2>
    <p>V&nbsp;roce 1918 připadají státu lesy císařské rodiny, nejvíce na Podkarpatské Rusi. Jde zhruba o 400 000 ha. Po parcelaci 1. pozemkovou reformou tak stoupá podíl státních lesů z&nbsp;2 % na 22 %. V&nbsp;roce 1938 je již 2,4 mil. ha ve vlastnictví státu.</p>
    <p>Vzniká podnik Státní lesy, tehdy byly snahy o pronajmutí státních lesů akciovým společnostem, což byl špatný nápad – nájemník by se k&nbsp;lesu choval s&nbsp;cílem ho co nejvíce vytěžit bez zvláštních starostí o trvalé udržení lesa do budoucna. Tento nápad pomůže zarazit i v&nbsp;roce 1919 vzniklá Vysoká škola zemědělská v&nbsp;Brně (dnes Mendelova univerzita).</p>
    <p>Během druhé světové války se snaží Němci snažit více zde než na své domovské půdě a vznikají zde poměrně rozsáhlé holiny, s&nbsp;ohledem na trvání války to však nějaký extrémní dopad na stav lesů nemělo.</p>
    <p>V&nbsp;roce 1945 jsou v&nbsp;rámci Košického národního programu znárodněny lesy nad 50 ha. Roku 1948 pak probíhá další vlna znárodňování, která na našem území probíhá zhruba do roku 1960. Pro zajímavost, lesy města Brna byly znárodněny v&nbsp;roce 1954. Na Slovensku však až do roku 1975 znárodnění odolávají znárodnění obecní lesy, tzv. urbariáty.</p>
    <p>Nastává zde zajímavý stav, majetnictví lesů bylo v&nbsp;některých případech sice zapsáno v&nbsp;pozemkových knihách a katastru. Stát však měl pro tyto lesy veškeré užívací právo a o veškerém hospodaření rozhodoval sám. Člověk však mohl peníze z&nbsp;lesa získat tehdy, když stát pozemek vykoupil například pro výstavbu dálnice.</p>
    <p>Dochází i k&nbsp;poškození lesů imisemi, viz kapitola o okyselení.</p>
    <hr />
    <h2>Stav lesů po revoluci</h2>
    <p>Po roce 1989 dochází postupně k&nbsp;restitucím majetku, v&nbsp;roce 2013 pak i k&nbsp;církevním restitucím. Před rokem 2013 bylo asi 66 % lesů státních, z&nbsp;čehož 54 % měly ve správě Lesy České republiky. Mezi další státní lesy patří ty pod správou ministerstva životního prostředí (NP, NPP, NPR), školní lesy (např. ŠLP Křtiny, ca 100 km2) a vojenské lesy. Dá se čekat, že vlivem restitucí klesne podíl státních lesů asi o 8 %.</p>
    <p>V&nbsp;současnosti si podnik Lesy České republiky najímá na práci v&nbsp;lese jiné společnosti a funguje spíše jako jakýsi dozor. To není též zcela ideální stav, zanechává to za sebou například rozryté cesty a poškozené stromy od těžké techniky.</p>
    <p>Dochází též k&nbsp;vzrůstu živin v&nbsp;lesích, viz kapitola o nadbytku živin. Dnes je rovněž ve velkém měřítku napadena třetí generace smrku kůrovcem (špatná stanoviště a jiné faktory) a rozmáhá se tzv. kalamitní těžba.</p>

    <hr />
    <ImgCon><Eko /><div>
    <h2>Studijn&iacute; materi&aacute;ly Ekologie a ŽP</h2>
    <p>T&eacute;ma <strong>Historie lesů na území České republiky</strong> je souč&aacute;st&iacute; <Link to="/ekologie-zivotni-prostredi/">studijn&iacute;ch materi&aacute;lů Ekologie a životní prostředí</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte n&aacute;sleduj&iacute;c&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/ekologie-zivotni-prostredi/"><button>Rozcestník ekologie a ŽP</button></Link>
    <Link to="/ekologie-zivotni-prostredi/dalsi-ekologicke-faktory/"><button className="inv">&larr; Další významné ekologické faktory</button></Link>
    <Link to="/ekologie-zivotni-prostredi/lesnicka-legislativa/"><button className="inv">Lesnická legislativa &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
